/*
This regex will break up an http path by <module> and <id> groups and should
pass for both integer and hashid forms, tested on:
  /projects/32/staff/debug
  /project-participants/32/staff/1234
  /project_participants/32/staff/debug
  /project_participants/pp-f837s73s/projects/1234
  /no-way/module/jose
*/
export const pathRegex = /(\/(?<module>[a-z\-_]+))(\/(?<id>(\d+)|([a-z]{2,3}-[a-z0-9]{6,8})))*/gi;

const specialRegex = {
  JOIN: /\/join\/[\w-]{5,}(?<remainder>\/.+)?/gi,
  R: /\/r\/[\w-]{5,}(?<remainder>\/.+)?/gi,

  FACEBOOK: /\/social\/facebook\/sharing\/(?<accessPath>screener|digest)\/(?<accessCode>[\w-]{10})(?<remainder>\/.+)?/gi,
  OPT_IN: /\/opt-in\/(?<access_code>[\w-]{24,})(?<remainder>\/.+)?/gi,

  PREPAID_BALANCES: /\/prepaid-balances\/(?<prepaidBalanceId>\d+\/)?invitations\/(?<code>[\w-]{48,})(?<remainder>\/.+)?/gi,
  PRE_TASK: /\/pre-task\/(?<projectParticipantSecretKey>\w{32})(?<remainder>\/.+)?/gi,
  PROJECT_APPLY: /\/projects\/(?<accessCode>[\w-]{10})\/apply(?<remainder>\/.+)?/gi,
  PROJECT_COLLAB: /\/projects\/accounts\/invitations\/(?<code>[\w\-\\=]{8,})\/accept(?<remainder>\/.+)?/gi,
  SURVEYS: /\/surveys\/(?<surveyCode>[\w-]{10})(\/(?<responseSetCode>[\w-]{10})(?<remainder>\/.+)?)?/gi,
};

type ParamsType = { [key: string]: string }[];
type UriDetails = { action: string, params: ParamsType }
type SpecialUriDetails = UriDetails | null;
export type ExtractedUriDetails = { action: string, params: { [key: string]: string } }

export function extractUriDetails(uri: string): ExtractedUriDetails {
  const uriDetails = specialUri(uri) || defaultUri(uri);

  return {
    action: uriDetails.action,
    params: uriDetails
      .params
      .reduce((acc, param) => ({ ...acc, ...param }), {}),
  }
}

function checkRegex(uri: string, regex: RegExp) {
  const result = uri.matchAll(regex).next();

  return result?.value;
}

function buildJoin(uri: string): SpecialUriDetails {
  const result = checkRegex(uri, specialRegex.JOIN);

  if (!result || !result.groups) { return null; }

  const { remainder } = result.groups;

  const actionResult = defaultUri(remainder);

  return {
    action: `/join/:referrer`.concat(actionResult.action),
    params: [],
  }
}

function buildFacebook(uri: string): SpecialUriDetails {
  const result = checkRegex(uri, specialRegex.FACEBOOK);

  if (!result || !result.groups) { return null; }

  const { accessPath, remainder } = result.groups;

  const actionResult = defaultUri(remainder);

  return {
    action: `/social/facebook/sharing/${accessPath}/:access_code`.concat(actionResult.action),
    params: [],
  }
}

function buildOptIn(uri: string): SpecialUriDetails {
  const result = checkRegex(uri, specialRegex.OPT_IN);

  if (!result || !result.groups) { return null; }

  const { remainder } = result.groups;

  const actionResult = defaultUri(remainder);

  return {
    action: `/opt-in/:access_code`.concat(actionResult.action),
    params: [],
  }
}

function buildPreTask(uri: string): SpecialUriDetails {
  const result = checkRegex(uri, specialRegex.PRE_TASK);

  if (!result || !result.groups) { return null; }

  const { remainder } = result.groups;

  const actionResult = defaultUri(remainder);

  return {
    action: `/pre-task/:project_participant_secret_key`.concat(actionResult.action),
    params: [],
  }
}

function buildProjects(uri: string): SpecialUriDetails {
  let action; let result;


  if (result = checkRegex(uri, specialRegex.PROJECT_APPLY)) {
    action = '/projects/:access_code/apply';

  } else if (result = checkRegex(uri, specialRegex.PROJECT_COLLAB)) {
    action = '/projects/accounts/invitations/:code/accept';
  } else {
    return null;
  }

  if (!result || !result.groups) { return null; }

  const { remainder } = result.groups;

  const actionResult = defaultUri(remainder);

  return {
    action: action.concat(actionResult.action),
    params: [],
  }
}

function buildPrepaidBalances(uri: string): SpecialUriDetails {
  const result = checkRegex(uri, specialRegex.PREPAID_BALANCES);

  if (!result || !result.groups) { return null; }

  const { prepaidBalanceId, remainder } = result.groups;
  const idTerm = prepaidBalanceId ? ':prepaid_balance_id/' : '';

  const actionResult = defaultUri(remainder);

  return {
    action: `/prepaid-balances/${idTerm}invitations/:code`.concat(actionResult.action),
    params: [],
  }
}

function buildR(uri: string): SpecialUriDetails {
  const result = checkRegex(uri, specialRegex.R);

  if (!result || !result.groups) { return null; }

  const { remainder } = result.groups;

  const actionResult = defaultUri(remainder);

  return {
    action: `/r/:general_referral_code`.concat(actionResult.action),
    params: [],
  }
}

function buildSurveys(uri: string): SpecialUriDetails {
  const result = checkRegex(uri, specialRegex.SURVEYS);

  if (!result || !result.groups) { return null; }

  const { responseSetCode, remainder } = result.groups;

  let action = '/surveys/:survey_code';

  if (responseSetCode) { action += '/:response_set_code'; }

  const actionResult = defaultUri(remainder);

  return {
    action: action.concat(actionResult.action),
    params: [],
  }
}

// access codes are 10 char urlsafe (/w\-){10}
function specialUri(uri: string) {
  return [
    buildJoin,
    buildFacebook,
    buildOptIn,
    buildPreTask,
    buildPrepaidBalances,
    buildProjects,
    buildR,
    buildSurveys,
  ].reduce<SpecialUriDetails>((value, actionBuilder) => value || actionBuilder(uri), null);
}

function defaultUri(uri: string): UriDetails {
  let action = '';
  const params: ParamsType = []

  if (!uri) {
    return {
      action,
      params,
    }
  }

  for (const result of uri.matchAll(pathRegex)) {
    if (result.groups) {
      const { module, id } = result.groups;

      let idTerm = '';

      if (id) {
        const idParam = module.endsWith('s') ? module.slice(0, module.length - 1) : module;
        idTerm = `/:${idParam}_id`;
        params.push({ [`${idParam}_id`]: id });
      }

      action += `/${module}${idTerm}`;
    }
  }

  return {
    action: action || uri,
    params,
  }
}
